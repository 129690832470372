import {VuexModule, Module, Action, Mutation} from "vuex-module-decorators";
import {
  WorkspaceApiFactory,
  WorkspaceWorkspaceDetail
} from "@/api";
import {axiosInstance} from "@/utils/axios";
import {isString} from "lodash";
import { EventBus } from "@/utils/EventBus";

let workspaceApi = WorkspaceApiFactory(undefined, undefined, axiosInstance)

const identificationAfterMinutes = 30

@Module({
  namespaced: true,
  name: 'App'
})
export default class App extends VuexModule {
  _locale: string = 'nl'
  _workspaces: Array<WorkspaceWorkspaceDetail> = []
  _workspace: WorkspaceWorkspaceDetail|null = null
  _packingZone: string|undefined = undefined
  _packingLocation: string|null = null
  _autoLogout: boolean = true
  _userName: string|null = null
  _identifiedAt: Date|null = null
  _shippedCount: number = 0

  get locale() {
    return this._locale;
  }

  get workspace() {
    return this._workspace
  }

  get userName() {
    return this._userName
  }

  get identifiedAt() {
    return this._identifiedAt
  }

  get shippedCount() {
    return this._shippedCount
  }

  get initials() {
    let name = this.userName
    if (!name) {
      return ''
    }
    let parts = name.split(' ')
    let inititals = ''
    for (let i = 0; i < parts.length; i++) {
      inititals += parts[i].charAt(0)
    }

    return inititals
  }

  get packingZone(): string|undefined {
    return this._packingZone
  }

  get packingLocation(): string|null {
    return this._packingLocation
  }

  get autoLogout(): boolean {
    return this._autoLogout
  }

  get secondsBeforeEnterPin(): number {
    let date = this._identifiedAt
    if (!date) {
        date = new Date()
    }
    if (isString(date)) {
        date = new Date(date)
    }

    let currentDate = new Date()
    let diffMilliseconds = currentDate - date;
    let diffMinutes = diffMilliseconds / (1000 * 60);
    if (diffMinutes > identificationAfterMinutes) {
        return 0
    }

    return Math.round((identificationAfterMinutes * 60) - (diffMinutes * 60))
  }

  @Mutation
  setWorkspace(workspace: WorkspaceWorkspaceDetail|null) {
    this._workspace = workspace
  }

  @Mutation
  setUserName(userName: string|null) {
    this._userName = userName
  }

  @Mutation
  setIdentifiedAt(identifiedAt: Date|null) {
    this._identifiedAt = identifiedAt
  }

  @Mutation
  setIdentifiedNeeded() {
    EventBus.$emit('identified-needed');
  }

  @Mutation
  setShippedCount(count: number) {
    this._shippedCount = count
  }

  @Mutation
  setPackingZone(packingZone: string|undefined) {
    this._packingZone = packingZone
  }

  @Mutation
  setPackingLocation(packingLocation: string|null) {
    this._packingLocation = packingLocation
  }

  @Mutation
  setAutoLogout(autoLogout: boolean) {
    this._autoLogout = autoLogout
  }

  get workspaces() {
    return this._workspaces;
  }

  @Mutation
  setWorkspaces(workspaces: Array<WorkspaceWorkspaceDetail>) {
    this._workspaces = workspaces
  }

  @Action({commit: 'setWorkspaces'})
  async loadWorkspaces() {
    const response = await workspaceApi.getWorkspaceCollection();
    return response.data['hydra:member'] || [];
  }
}
